<script lang="ts" setup>
import SiteFooter from '@/components/SiteFooter.vue'
import SiteHeader from '@/components/SiteHeader.vue'
import SkrButton from '@/components/button/SkrButton.vue'

const route = useRoute()

const layoutMeta = computed(() => {
  return {
    login: true,
    ...route.meta.unauthenticated,
  }
})

const display = useDisplay()
</script>

<template>
  <v-main>
    <site-header>
      <template v-if="layoutMeta.login" #right>
        <div v-if="display.smAndUp" class="d-flex align-center">
          <span class="mr-4">{{ $t('signup.already_have_account') }}</span>
          <skr-button type="secondary" size="lg" :to="{ name: 'login' }">
            <v-icon start>custom:padlock</v-icon>
            {{ $t('global.login') }}
          </skr-button>
        </div>
        <skr-button v-else :to="{ name: 'login' }" type="secondary" size="lg" rounded>
          <v-icon size="medium">custom:padlock</v-icon>
        </skr-button>
      </template>
    </site-header>
    <div class="content">
      <slot />
    </div>
    <site-footer />
  </v-main>
</template>

<style lang="sass" scoped>
.content
  height: 100%
  max-width: calc(#{$site-width} + #{($base-width * 3 * 2)})
  margin: 0 auto
</style>
